require('./src/styles/global.css');
const { Link } = require('gatsby');
const { setLinkComponent } = require('./src/components/core/Link');

exports.onClientEntry = () => {
  setLinkComponent(Link);
};

exports.onRouteUpdate = ({ location }) => {
  console.log(`Changed page: ${location.pathname}`);
  window.freshsales &&
    window.freshsales.trackPageView(
      `thinkreservations.com${location.pathname}`
    );
};
