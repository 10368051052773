import React, { Component } from 'react';

let _LinkComponent;

export const setLinkComponent = (myComponent) => {
  _LinkComponent = myComponent;
};

export class Link extends Component {
  render() {
    if (_LinkComponent) {
      const myProps = {
        ...this.props,
        children: undefined,
      };

      return React.createElement(_LinkComponent, myProps, this.props.children);
    } else {
      const myProps = {
        ...this.props,
        href: this.props.to,
        to: undefined,
        children: undefined,
      };
      return <a {...myProps}>{this.props.children}</a>;
    }
  }
}

export default Link;
